import axios from 'axios';

import { User } from './types';

const USER = `https://43bzettkv62gcuee5zgnk4hk2y0bikiu.lambda-url.us-east-1.on.aws`;

const headers = {
  'Content-Type': 'text/plain',
  'Accept': '*/*'
}

class LoginApi {

  public login = async (user: User) => {
    try {
      return axios.post(`${USER}`,user, { headers });
    } catch (e) {
      throw e;
    }
  }

}
export default LoginApi;