import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import AppIcon, { OpenInNew, Star } from '@material-ui/icons';
import CodeIcon from '@material-ui/icons/Code';
import { makeStyles } from '@material-ui/core/styles';
import { CardHeader } from '@material-ui/core';
import logo from '../../images/logo.png';
import './index.css';

const useStyles = makeStyles({
    media: {
        height: '18em',
    },
});

const translate = (key: string) => {
    if (key === 'home.begin') {
        return 'Begin';
    }
    if (key === 'home.welcome.title') {
        return 'welcome to deliveryscale.com';
    }
    if (key === 'home.welcome.subtitle') {
        return 'to deliveryscale.com';
    }
    if (key == 'home.product') {
        return 'product';
    }
    if (key == 'home.fullstack') {
        return 'fullstack';
    }
    if (key == 'home.login') {
        return 'Login';
    }
    if (key == 'home.set') {
        return 'set';
    }
    if (key == 'home.privacy') {
        return 'Privacy Policy';
    }
    if (key == 'home.terms') {
        return 'Terms of Use';
    }
    if (key == 'home.copyright') {
        return '© deliveryscale.com 2024. All rights reserved. ';
    }
    return key;
};

const Home = () => {
    const classes = useStyles();
    return (
        <Container>
        <div className="home">
        <img aria-hidden="true" src={logo} />
        <Button href="/login">
        {translate('home.login')}
        </Button>
        </div>
        <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid direction='row' xs={12} style={{textAlign: "center"}}>
                <Card elevation={0}>
                    <CardContent>
                        <div>
                        </div>
                        <Typography variant="h5" component="h2">
                            {translate('home.welcome.title')}
                        </Typography>
                        <Typography color="textSecondary">
                        You’ve got great products to sell but not-so-amazing delivery and ecommerce chops. Yet you’re competing with online giants with big pockets and delivery plus ecommerce know-how. We are leveling the playing field by allowing you to plug into an enterprise-class delivery and ecommerce platform that handles ALL of your commerce needs. Break the cycle of constant upgrades, integrations, and custom R&D. Plug into our intelligent delivery and ecommerce platform today.
                    </Typography>
                    </CardContent>
                </Card>
                <Grid direction='column' xs={12} container spacing={1} justify="center" style={{textAlign: "center", height:"100%"}}>
                <Box m={1} pt={1}>
                <Card elevation={5} style={{textAlign: "center"}}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                          Grocery & Convenience
                        </Typography>
                        <Typography color="textSecondary">
                        The consumer has changed since the pandemic.
                        While they maybe back at work, they need convience now more than ever.
                        </Typography>
                    </CardContent>
                </Card>
                </Box>
                <Box m={1} pt={1}>
                <Card elevation={5} style={{textAlign: "center"}}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Retail
                        </Typography>
                        <Typography color="textSecondary">
                        Deliver almost anything with our retail offering.
                        </Typography>
                    </CardContent>
                </Card>
                </Box>
                <Box m={1} pt={1}>
                <Card elevation={5} style={{textAlign: "center"}}>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Restaurants / Virtual Kitchens
                        </Typography>
                        <Typography color="textSecondary">
                        Food delivery will never be the same. Are you scaling with your competition?
                        </Typography>
                    </CardContent>
                </Card>
                </Box>
                </Grid>
                <Card elevation={0}>
                    <CardContent>
                        <Paper elevation={0}>
                            <Typography>{translate('home.copyright')}</Typography>
                        </Paper>
                        <Button href="https://deliveryscale.com/terms-of-use.html">
                            <OpenInNew style={{ paddingRight: '0.5em' }} />
                            {translate('home.terms')}
                        </Button>
                        <Button href="https://deliveryscale.com/privacy-policy.html">
                            <OpenInNew style={{ paddingRight: '0.5em' }} />
                            {translate('home.privacy')}
                        </Button>
                    </CardContent>
                </Card>
            </Grid>            
        </Grid>
        </Container>
    );
};

export default Home;
