import React, { useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import logo from '../../images/logo.png';
import MaterialTable from 'material-table';
import deliverySend from '../../images/delivery-send.png';
import deliveryCancel from '../../images/delivery-cancel.png';
import OrdersApi from './api';
import { Order } from './order';
import './index.css';

const state = {
  orders: []
};

function setState(state: any) {
  state = state;
}

const translate = (key: string) => {
  if (key === 'home.logout') {
      return 'Logout';
  }
}



export default function Orders() {

  const [page] = useState(1);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  const submitLogout = async () => {
    try {
      localStorage.setItem('Id', '');
      navigate(`/`);
    } catch (err) {
      throw Error('Unable to submit logout');
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      const response = await new OrdersApi().getOrders();
      console.log(response.data);
      setOrders(response.data.Items);
    }

  fetchData()
  .catch(console.error);


    // .catch(error => {
    //   console.error(error);
    //   throw Error('Unable to get orders');
    // });
  }, [page]);


  return (
    <Container>
    <div className="orders">
    <a href={'/'} tabIndex={-1} rel="noreferrer" target="_blank">
      <img aria-hidden="true" src={logo} />
    </a>
    <Button onClick={() => submitLogout()}>
    {translate('home.logout')}
    </Button>
    </div>
    <MaterialTable
      title=""
      actions={[
        {
          icon: () => <img aria-hidden="true" src={deliverySend} />,
          tooltip: "Deliver",
          onClick: (event, rowData) => {
            const rowJson = JSON.stringify(rowData, null, 2);
            const answer = window.confirm(`Send For Delivery? : ${rowJson}`);
            if (answer) {
              //send for delivery
              console.log(answer)
            }
            else {
                //some code
                console.log('else')
            }
          },
        },
        {
          icon: () => <img aria-hidden="true" src={deliveryCancel} />,
          tooltip: "Cancel",
          onClick: (event, rowData) => {
            const rowJson = JSON.stringify(rowData, null, 2);
            const answer = window.confirm(`Cancel Delivery? : ${rowJson}`);
            if (answer) {
              //cancel for delivery
              console.log(answer)
            }
            else {
                //some code
                console.log('else')
            }
          },
        },
      ]}
      columns={[
        { title: 'Shopify Order Number', field: 'OrderNumber.S' },
        { title: 'Shopify Customer', field: 'OrderCustomer.S' },
        { title: 'Shopify Order Items', field: 'OrderLineItems.S' },
        ]}
      data={orders}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              new OrdersApi().orders.put(newData)
              .then(res => res.json())
              .then(response => {
                console.log(response.data.Items)
                  setOrders(response.data.Items);
              })
              .catch(error => {
                throw Error('Unable to put orders');
              });
              setState((prevState: { data: any; }) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }, 600);
          }),
        onRowUpdate: (newData:any, oldData:any) =>
          new Promise((resolve) => {
            setTimeout(() => {
              new OrdersApi().orders.patch(newData)
              .then(res => res.json())
              .then(response => {
                  
                  setOrders(response.data.Items);
              })
              .catch(error => {
                throw Error('Unable to patch orders');
              });
              if (oldData) {
                setState((prevState: { data: any; }) => {
                  const data = [...prevState.data];
                  data[data.indexOf(oldData)] = newData;
                  return { ...prevState, data };
                });
              }
            }, 600);
          }),
        onRowDelete: (oldData:Order) =>
          new Promise((resolve) => {
            setTimeout(() => {
              new OrdersApi().orders.delete(oldData)
              .then(res => res.json())
              .then(response => {
                  setOrders(response.data.orders);
              })
              .catch(error => {
                throw Error('Unable to delete orders');
              });
              setState((prevState: { data: any; }) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
    </Container>
  );
}