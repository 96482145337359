import axios from 'axios';

import { Order } from './order';


import { REACT_APP_API_ROOT } from '../../constants';

const ORDERS_GET = `https://fofuxpnvnayr4yclq5iuyw4jci0ktwli.lambda-url.us-east-1.on.aws`;
const ORDERS = `${REACT_APP_API_ROOT}`;

const headers = {
  'Content-Type': 'text/plain',
  'Accept': '*/*'
}
class OrdersApi {


  public getOrders = async (): Promise<any> => {
    try {
      try {
        //headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        return axios.get(`${ORDERS_GET}`, { headers });
      } catch (e) {
        throw e;
      }
    } catch (e) {
      throw e;
    }
  }

    orders = {
      get: async (): Promise<any> => {
          try {
            //headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
            return axios.get(`${ORDERS_GET}`, { headers });
          } catch (e) {
            throw e;
          }
      },
      getOne: async (order:Order): Promise<any> => {
        try {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          return axios.get(`${ORDERS}/${order.id}`, { headers });
        } catch (e) {
          throw e;
        }
      },
      put: async (order:Order): Promise<any> => {
        try {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          return axios.put(`${ORDERS}`,order, { headers });
        } catch (e) {
          throw e;
        }
      },
      patch: async (order:Order): Promise<any> => {
        try {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          return axios.patch(`${ORDERS}/${order.id}`, { headers });
        } catch (e) {
          throw e;
        }
      },
      delete: async (order:Order): Promise<any> => {
        try {
          headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          return axios.delete(`${ORDERS}/${order.id}`, { headers });
        } catch (e) {
          throw e;
        }
      },
  };


}
export default OrdersApi;